export default [
  {
    path: '/painel',
    name: 'panel',
    component: () => import('@/views/panels/panel.vue'),
    meta: {
      resource: 'panel',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Painéis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/panels/comercial',
    name: 'panels-comercial',
    component: () => import('@/views/panels/comercial.vue'),
    meta: {
      resource: 'panel-commercial',
      action: 'read',
    },
  },
  {
    path: '/panels/gabinete',
    name: 'panels-gabinete',
    component: () => import('@/views/panels/gabinete.vue'),
    meta: {
      resource: 'panel-cabinet',
      action: 'read',
    },
  },
  {
    path: '/panels/daf',
    name: 'panels-daf',
    component: () => import('@/views/panels/daf.vue'),
    meta: {
      resource: 'panel-daf',
      action: 'read',
    },
  },
  {
    path: '/panels/diop',
    name: 'panels-diop',
    component: () => import('@/views/panels/diop.vue'),
    meta: {
      resource: 'panel-diop',
      action: 'read',
    },
  },
  {
    path: '/panels/projects',
    name: 'panels-projects',
    component: () => import('@/views/panels/projects.vue'),
    meta: {
      resource: 'panels-projects',
      action: 'read',
    },
  },
  {
    path: '/panels/proc',
    name: 'panels-proc',
    component: () => import('@/views/panels/proc.vue'),
    meta: {
      resource: 'panels-proc',
      action: 'read',
    },
  },
]
